import axios from "axios";
import { createContext, useEffect } from "react";
import useStateRef from "react-usestateref";
import { getLangs } from "../constants/urls";

const LangContext = createContext({
  langs: [],
  get_langs: () => {},
});

export function LangContextProvider(props) {
  const [langs, setLangs, langsRef] = useStateRef([]);

  useEffect(() => {
    axios.get(getLangs).then((res) => {
      setLangs(res.data);
    });
  }, []);

  const context = {
    langs: langsRef.current,
  };

  return (
    <LangContext.Provider value={context}>
      {props.children}
    </LangContext.Provider>
  );
}

export default LangContext;
