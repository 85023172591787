
import { AppBar } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PreviosPage = () => {
    const navigate = useNavigate();
    const handeClickBack = () => {
        navigate(-1);
    };
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const prevIcon = document.querySelector('.btn-prev');
        const scrollTop = window.scrollY;
        scrollTop < 100? prevIcon.classList.add('is-sticky') : prevIcon.classList.remove('is-sticky');
    };
    return (
       
       <button onClick={handeClickBack} className="border-0 bg-white btn-prev " >
        <i className="fas fa-chevron-left" style={{color:'#4C4C47'}}></i>
        </button>
     
    )
} 

export default PreviosPage
