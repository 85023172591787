import { Grid, Skeleton } from "@mui/material";

const LoadingSkeleton = () => {
  return (
    <>
      <Grid item xs={12} sx={{ padding: "0 5px", margin: "20px 0" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: "0 5px", margin: "20px 0" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: "0 5px", margin: "20px 0" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid item xs={12} sx={{ padding: "0 5px", margin: "20px 0" }}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
    </>
  );
};

export default LoadingSkeleton;
