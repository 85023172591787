
import { TabContext, TabList } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Tab, Typography } from "@mui/material";
import FilterItem from './FilterItem';
import { ITEMS, STORAGE } from '../../constants/urls';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import ItemBox from './ItemBox';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import OrderContext from '../../store/OrderContext';
import { useContext } from 'react';
import Currency from './Currency';
import i18n from '../../i18n';


const CategoryBar = () => {
    const curr_lang = i18n.language
    const { t } = useTranslation();
    const navigate = useNavigate();
    const orderContext = useContext(OrderContext);
    const token = localStorage.getItem('token')
    const catResult = JSON.parse(localStorage.getItem("result"));
    const [value, setValue] = useState(window.location.href.substring(window.location.href.lastIndexOf("/") + 1));
    const [num, setNum] = useState(0);
    const [loading, setLoading] = useState(true)
    const [info, setInfo] = useState([])
    const [tempInfo, setTempInfo] = useState([])
    const [select, setSelect] = useState('');
    const [reload, setReload] = useState(false)
    const FetchItems = async () => {
        setLoading(true)
        if (token != null) {
            await axios.get(ITEMS, { headers: { 'Currency': orderContext.currencyState } })
                .then((response) => {
                    if (response.status === 200) {
                        setInfo(response.data.data)
                        setTempInfo(response.data.data)


                    }
                    setLoading(false)
                })
                .catch((error) => {
                    if (error.status === 401) {
                        window.location.reload();
                        localStorage.removeItem('token');
                        localStorage.removeItem("new");
                        localStorage.removeItem('total')
                        orderContext.items = [];
                        orderContext.totalPrice = 0;
                        navigate("/");
                    }
                });
        } else {
            navigate("/");
        }
        setLoading(false)
    }

    const ResetSearch = () => {
        orderContext.setresSearch1("")
        orderContext.setSearch(null);

    }

    // filter item select
    const handleSelect = function (value) {
        setSelect(value);
        if (value !== 'all') {
            const newInfo = info.filter(e => (e[value] === 1))
            setTempInfo(newInfo);
        } else {
            setTempInfo(info);
        }
    }
    useEffect(
        () => {
            FetchItems()
        },
        [reload])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            {orderContext.resSearch == null ?
                <TabContext value={value} >
                    <TabList onChange={handleChange} variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example" >
                        <Tab label={t("Trended")} value='trend' icon={<div className='icon-box'><img src={require('../../assets/img/Vector2.png')} alt="icon" /></div>} />
                        {catResult.map((item) => (
                            <Tab key={item.id} label= {item.translations.name[curr_lang]} value={item.id + ""}
                                icon={<div className='icon-box'><img src={item.icon != null && `${STORAGE}${item.icon}`} alt="category" /></div>}
                            />
                        ))}
                    </TabList>
                    <div className='d-flex justify-content-between select-group'>
                        <FilterItem select={select} setSelect={handleSelect} />
                        <Currency reload={reload} setReload={setReload} />
                    </div>

                    {loading ? <LoadingSkeleton /> :
                        <>
                            {tempInfo.length !== 0 ?
                                <>
                                    {tempInfo.filter(item => (item.is_trending === 1)).map((i) => (
                                        <TabPanel value="trend" className='tap-last' key={i.id} id={i.id}>
                                            <ItemBox item_box={i}
                                                num={num} setNum={setNum}
                                            />
                                        </TabPanel>
                                    ))}

                                    {tempInfo.filter(item => (item.is_trending !== 1)).map((i) => (
                                        <TabPanel value={i.category_id + ""} key={i.id} id={i.id} className="tap-last" >
                                            <ItemBox item_box={i}
                                                num={num} setNum={setNum} />
                                        </TabPanel>
                                    ))}
                                </>
                                : <Typography variant="h6" className='text-center' sx={{ mb: 5 }}> {t("NoInformationItems")} </Typography>
                            }
                        </>
                    }

                </TabContext >
                :
                <>
                    {orderContext.resSearch.length != 0 ?
                        orderContext.resSearch.map((res) => (
                            <ItemBox item_box={res}
                                num={num} setNum={setNum} />
                        )) :
                        <div className="text-center py-5">
                            <h5 className="text-center mb-4">{t('NoInformationSearch')}</h5>
                            <button onClick={ResetSearch} className="button-search">{t('backBtn')}</button>
                        </div>

                    }
                </>
            }
        </>
    )
}

export default CategoryBar