import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { SEARCH } from '../constants/urls';
import { useNavigate } from 'react-router-dom';
import OrderContext from '../store/OrderContext';
import { useContext } from 'react';
import { InputAdornment, TextField } from '@mui/material';




const SearchBar = () => {
    const [loading, setLoading] = useState(false)
    const orderContext = useContext(OrderContext);
    const navigate = useNavigate();
    const handlechange = async (e) => {
        orderContext.setresSearch1(e.target.value)
        setLoading(true)
        const data = {
            q: orderContext.resSearch1
        }
        await axios.post(SEARCH, data, { headers: { Accept: "application/json", 'Currency': orderContext.currencyState } }
        )
            .then((response) => {
                if (response.status === 200) {
                    if (e.target.value === '') {
                        orderContext.setSearch(null)
                    } else {
                        orderContext.setSearch(response.data)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            });
        setLoading(false)

    }
    const handleClickRef = (e) => {
        orderContext.refInput.current.focus();
    }
    return (
        <>
            <div className='search-box position-relative mb-3'>
                <input ref={orderContext.refInput} type='text' className='form-control' placeholder='Search' value={orderContext.resSearch1} onChange={handlechange} />
                {loading ? <p style={{ color: '#4DC0D0' }} className='position-absolute border-0 bg-transparent btn-search'>Please, wait ...</p> :
                    <div className='position-absolute border-0  btn-search '>

                        <input type='button' onChange={handlechange} hidden />
                        <SearchIcon style={{ color: '#4C4C47', cursor: 'pointer' }} onClick={handleClickRef} />
                    </div>
                }

            </div>


        </>
    )
}

export default SearchBar