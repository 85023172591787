import { useState, useEffect } from "react"
import PreviosPage from "../../components/PreviosPage"
import SearchBar from "../../components/SearchBar"
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Container, Typography, CircularProgress, Snackbar, Alert, Pagination, Stack } from '@mui/material';
import { useContext } from "react";
import OrderContext from "../../store/OrderContext";
import ItemOrder from "./ItemOrder";
import { ORDER, ORDER_TABLE, STORAGE, TIMER } from "../../constants/urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import i18n from "../../i18n";

const OrdersPage = () => {
    const { t } = useTranslation();
    const curr_lang = i18n.language
    const orderContext = useContext(OrderContext)
    const navigate = useNavigate();
    const token = localStorage.getItem('token')
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [open, setOpen] = useState(false);
    const [timer, setTimer] = useState('')
    const [message, setMessage] = useState(false) 
    const [info, setInfo] = useState([])
    const [infoLoaded, setInfoLoaded] = useState(false);
    const [reload, setReload] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(1);

    // const filter = (e) => {
    //     orderContext.setresSearch1(e.target.value);
    //   const res =    orderContext.items.filter((item) => item.name.includes(orderContext.resSearch1));
    //   console.log(r)
    // }

    // useEffect(() => {
    //     if (orderContext.resSearch1 === "") {
    //         setLoading2(false)
    //     } else {
    //         setLoading2(true)
    //     }
    // }, [orderContext.resSearch1]);

    const handleChangePage = (event, value) => {
        setPage(value);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const FetchTTLValue = async () => {
        await axios.get(TIMER)
            .then((response) => {
                setTimer(response.data.data.JWT_TTL)
            })
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const FinishOrder = async (e) => {
        setLoading(true)
        const btnValue = e.target.value
        const data = {
            last_order: JSON.parse(btnValue),
            items: orderContext.items
        }
        await axios.post(ORDER, data, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            }
        })
            .then((response) => {
                setOpen(false);
                setMessage(true);
                localStorage.removeItem("new");
                localStorage.removeItem('total')
                orderContext.items = [];
                orderContext.totalPrice = 0;
                setReload(true)
            })

            .catch((error) => {
                console.log(error)
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    navigate('/')

                }
            });
        setLoading(false);

    }


    const handleClose = (event) => {
        setOpen(false)
        setTimeout(() => FinishOrder(event), timer);


    };

    const FetchOrdersTable = async () => {
        setInfoLoaded(true)
        await axios
            .get(`${ORDER_TABLE}?page=${page}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + token,
                    'Currency': orderContext.currencyState
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                    setTotalPage(response.data.meta.last_page);

                }
            })
            .catch((error) => {
                if (error.status === 401) {
                    localStorage.removeItem('token')
                    navigate('/')

                }
            })
        setInfoLoaded(false)
    };
    const ResetSearch = () => {
        orderContext.setresSearch1("")
        orderContext.setSearch(null);

    }
    useEffect(
        () => {
            FetchTTLValue()
            FetchOrdersTable()
        },
        [reload, page])

    return (

        <Container maxWidth="sm" className="pt-4 bg-white page">



            <div className='row align-items-center mb-4'>
                <div className='col-md-3 col-sm-2 col-2'> <PreviosPage /> </div>
                <div className='col-md-6 col-sm-7 col-7'>
                    <h2 className="title fw-bold text-center mb-0" style={{ color: '#4DC0D0' }}>{t("myOrder_link")}</h2>
                </div>
                <div className='col-md-3 col-sm-3 col-3 co-total'>
                    <div className="total fw-bold text-center"> {orderContext.totalPrice} {orderContext.currencyState}</div>
                </div>
            </div>
            <SearchBar />

            {orderContext.resSearch == null ?
                <div className="mt-4 " >
                    {/* orders */}
                    {orderContext.items.length != 0 ?
                        orderContext.items.map((item) => (
                            <ItemOrder item_box={item} key={item.id} />
                        ))

                        : infoLoaded ? <LoadingSkeleton /> :
                            <>
                                {/* all orders  */}
                                {info.length != 0 ?
                                    info.map((i) => (
                                        <div className="main-box position-relative" key={i.id}>
                                            {i.Items.length > 0 && i.Items.map((it) => (
                                                <div className="box position-relative box-item box2 " key={it.id} id={it.id}>
                                                    <div className="order-status">{t("Order_Status")}: {i.order_status}</div>
                                                    <div className="box-img mb-2 position-relative">
                                                        <img src={it.image != null && `${STORAGE}${it.image}`} alt="category" width="100%" />
                                                    </div>
                                                    <p className="fw-bold mb-2">{it.translations.name[curr_lang]}</p>
                                                    <div className="row align-items-center mb-2">
                                                        <div className="col-6">
                                                            <div className="price mb-0">
                                                                {it.offer_price !== null ? (
                                                                    <div>
                                                                        <span className="new-price fw-bold">
                                                                            {it.offer_price} {orderContext.currencyState}
                                                                        </span>
                                                                        <span className="old-price">{it.price} {orderContext.currencyState}</span>
                                                                    </div>
                                                                ) : (
                                                                    <div className="new-price fw-bold">{it.price} {orderContext.currencyState}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-end">
                                                            <p className="mb-0">{t("Quantity")}: <span className="fw-bold">{it.pivot.quantity}</span></p>
                                                        </div>
                                                    </div>
                                                    {it.pivot.notes === null ? '' :
                                                        <>
                                                            <p>{t("note_text")}: <span>{it.pivot.notes}</span></p>

                                                        </>
                                                    }
                                                </div>
                                            ))}


                                        </div>
                                    ))
                                    : <Typography variant="h6" className='text-center pb-5' sx={{ mb: 5 }}> {t("NoInformationOrder")} </Typography>}
                                <Stack spacing={2} sx={{ padding: 2 }}>
                                    <Pagination
                                        page={page}
                                        onChange={handleChangePage}
                                        count={totalPages}
                                        variant="outlined"
                                        sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
                                    />

                                </Stack>
                            </>

                    }
                </div>
                : <>
                    {orderContext.resSearch.length != 0 ?
                        orderContext.resSearch.map((res) => (
                            info.map((i) => (
                                <div className="main-box position-relative" >
                                    {i.Items.length > 0 && i.Items.map((it) => (
                                        <div className="box position-relative box-item box2 " >
                                            <div className="order-status">{t("Order_Status")}: {i.order_status}</div>
                                            <div className="box-img mb-2 position-relative">
                                                <img src={it.image != null && `${STORAGE}${it.image}`} alt="category" width="100%" />
                                            </div>
                                            <p className="fw-bold mb-2">{it.translations.name[curr_lang]}</p>
                                            <div className="row align-items-center mb-2">
                                                <div className="col-6">
                                                    <div className="price mb-0">
                                                        {it.offer_price !== null ? (
                                                            <div>
                                                                <span className="new-price fw-bold">
                                                                    {it.offer_price} {orderContext.currencyState}
                                                                </span>
                                                                <span className="old-price">{it.price} {orderContext.currencyState}</span>
                                                            </div>
                                                        ) : (
                                                            <div className="new-price fw-bold">{it.price} {orderContext.currencyState}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <p className="mb-0">{t("Quantity")}: <span className="fw-bold">{it.pivot.quantity}</span></p>
                                                </div>
                                            </div>
                                            {it.pivot.notes === null ? '' :
                                                <>
                                                    <p>{t("note_text")}: <span>{it.pivot.notes}</span></p>

                                                </>
                                            }
                                        </div>
                                    ))}


                                </div>
                            )))) :
                        <div className="text-center py-5">
                            <h5 className="text-center mb-4">No Result</h5>
                            <button onClick={ResetSearch} className="button-search">Back to menu</button>
                        </div>

                    }
                </>}
            {orderContext.items.length != 0 ?
                <Container maxWidth="sm" className="bottom-footer px-0" sx={{ padding: '0' }}>
                    <button className="btn-order" onClick={handleClickOpen}>{t("button-send")}</button>
                </Container>
                : ''}
            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Poppins', color: '#4C4C47' }}>
                    {t("modal_title")}
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    {loading ? <Button ><CircularProgress size={25} style={{ color: '#fff' }} /> </Button> :
                        <Button onClick={FinishOrder} value="true" > {t("yesButton")} </Button>
                    }
                    <Button onClick={handleClose} value="false" >  {t("noButton")}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={message} autoHideDuration={4000} onClose={handleCloseMessage} className="ic">
                <Alert onClose={handleCloseMessage} severity="success" sx={{ width: '100%' }} style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}>
                    {t("success-message")}
                </Alert>
            </Snackbar>

        </Container>


    )
}

export default OrdersPage