import React, { useState } from "react";
import "./assets/css/style.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Footer from "./pages/footer/Footer";
import Home from "./pages/Home/Home";
import ItemPage from "./pages/items/ItemPage";
import OrdersPage from "./pages/order/OrdersPage";
import About from "./pages/about/About";
import { useEffect } from "react";
import PreLoder from "./components/PreLoder";
// import Table from "./pages/table/Table";


export default function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {loading === false ? (
        <BrowserRouter>
          <Routes>
            {/* <Route element={<Table />} path='/' /> */}
            <Route element={<Home />} path="/" />
            <Route element={<ItemPage />} path="/items/:id" />
            <Route element={<ItemPage />} path="/items/trend" />
            <Route element={<OrdersPage />} path="/orders" />
            <Route element={<About />} path="/about-us" />

          </Routes>
          <Footer />
        </BrowserRouter>
      ) : (
        <PreLoder />
      )}
    </>
  );
}
