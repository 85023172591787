import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterItem = (props) => {
    const { t } = useTranslation();
    const handleChange = (value) => {
        props.setSelect(value);
    };
    return (
        <div className="filter-section">
            <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="demo-simple-select-filled-label">{t("filter_title")}</InputLabel>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={props.select}
                    onChange={(e) => handleChange(e.target.value)}
                >
                    <MenuItem value="all">{t("all")}</MenuItem>
                    <MenuItem value="vegan">{t("vegan")}</MenuItem>
                    <MenuItem value="lactose_free">{t("lactose_free")}</MenuItem>
                    <MenuItem value="gluten_free">{t("gluten_free")}</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

export default FilterItem