import { set } from 'date-fns';
import { useRef, useState } from 'react';
import { createContext, useEffect } from 'react';

import useStateRef from "react-usestateref";

const OrderContext = createContext({
    items: [],
    totalPrice: 0,
    add_item: (item_id) => { },
    remove_item: (item_id) => { },
    checkHandlerfun: (item_id) => { },
    setTotal: (price) => { },
    store: () => { },
    resSearch: [],
    setSearch: () => { },
    resSearch1: "",
    setresSearch1: () => { },
    refInput: null,
    currencyState: '$',
    currencyStateFun: () => { },
    reset: () => { }
});

export function OrderContextProvider(props) {
    const [orderitems, setOrderItems, orderItemsRef] = useStateRef([])
    const [ototal, setOtotal, totalRef] = useStateRef(0)
    const localStorageitems = JSON.parse(localStorage.getItem("new"))
    const TotalStorage = localStorage.getItem('total')
    const [contextsearch, setContextSearch] = useStateRef(null)
    const [currency, setCurrency] = useStateRef('$')
    const ref = useRef(null);
    // state input search
    const [search1, setSearch1, searchRef] = useStateRef('')
  
    useEffect(() => {
        if (localStorageitems) {
            setOrderItems(localStorageitems)
            setOtotal(TotalStorage)
        }
    }, [])

    function addOrderItemHandler(orderItem) {
        setOrderItems((prevOrderItem) => [...prevOrderItem, orderItem]);
        store();

    }
    function removeOrderItemHandler(itemId) {
        setOrderItems(prevOrderItem => {
            return prevOrderItem.filter(item => item.id !== itemId);
        });
    }

    function checkHandler(itemId) {
        return orderitems.some(item => item.id === itemId);
    }

    const resetItems = function () {
        setOrderItems([]);
        setOtotal(0)
    }

    const context = {
        items: orderitems,
        totalPrice: ototal,
        add_item: addOrderItemHandler,
        reset: resetItems,
        remove_item: removeOrderItemHandler,
        checkHandlerfun: checkHandler,
        setTotal: setOtotal,
        store: store,
        resSearch: contextsearch,
        setSearch: setContextSearch,
        resSearch1: search1,
        setresSearch1: setSearch1,
        refInput: ref,
        currencyState: currency,
        currencyStateFun: setCurrency
    };




    function store() {
        localStorage.setItem("new", JSON.stringify(orderItemsRef.current));
        localStorage.setItem("total", totalRef.current);
    }



    return (
        <OrderContext.Provider value={context}>
            {props.children}

        </OrderContext.Provider>
    );

}


export default OrderContext;
