import { Link } from "react-router-dom";
import { CATEGORIES, STORAGE } from "../../constants/urls";
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import emptyImage from "../../assets/img/no-image.png";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const CategoryPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState([]);
  const curr_lang = i18n.language


  const FetchCategories = async () => {
    setLoading(true);
    await axios
      .get(CATEGORIES)
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
          localStorage.setItem("result", JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error.status);
      });

    setLoading(false);
  };

  useEffect(() => {
    FetchCategories();
  }, []);
  return (
    <div className="container-padding2">
      <h2 className="title fw-bold mb-4">{t("cat_title")}</h2>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <>
          {info.length != 0 ?
            <Link to="/items/trend">
              <div className="box position-relative">
                <div className="box-img mb-4  position-relative">
                  <img
                    src={require("../../assets/img/about-img.png")}
                    alt="about"
                    width="100%"
                  />
                </div>
                <div className="position-absolute list-cat w-100 text-center">
                  <h2 className="title2 text-white fw-bold">{t("Trended")}</h2>
                </div>
                <div className="position-absolute bottom-0 end-0">
                  <img
                    src={require("../../assets/img/fire-icon.png")}
                    className="fire-icon"
                    alt="fire-icon"
                  />
                </div>
              </div>
            </Link>
            : ''}
          {info.length !== 0 ? (
            info.map((item) => (
              <Link to={`/items/${item.id}`} key={item.id} id={item.id}>
                <div className="box position-relative">
                  <div className="box-img mb-4  position-relative">
                    <img
                      src={
                        item.image != null
                          ? `${STORAGE}${item.image}`
                          : emptyImage
                      }
                      alt="category"
                    />
                  </div>
                  <div className="position-absolute list-cat w-100 text-center">
                    <h2 className="title2 text-white fw-bold" >
                      {item.translations.name[curr_lang]}
                    </h2>


                  </div>
                </div>
              </Link>
            ))
          ) : (
            <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
              {" "}
              {t("NoInformationCat")}{" "}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryPage;
