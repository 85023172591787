import { Link } from "react-router-dom";
import LanguageDropDown from "../../components/LanguageDropDown";
import CategoryPage from "../categories/CategoryPage";
import { Container } from "@mui/material";
import Table from "../table/Table";
import axios from "axios";
import { useState } from "react";
import { SOCIAL } from "../../constants/urls";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Home = () => {
  const token = localStorage.getItem("token");
  const [list, setList] = useState([]);
  const { t } = useTranslation();
  const FetchSocialMedia = async () => {
    // setLoading(true);
    await axios
      .get(SOCIAL)
      .then((response) => {
        if (response.status === 200) {
          setList(response.data.data);
        }
      })
      .catch((error) => { });

    // setLoading(false);
  };
  useEffect(() => {
    FetchSocialMedia();
  }, []);

  return (
    <>
      {token === null ? (
        <Table />
      ) : (
        <Container
          maxWidth="sm"
          sx={{ padding: "0" }}
          className="bg-white page px-0"
        >
          <div className="banner-intro">
            <div className="content">
              <h1 className="fw-bold mb-0">{t("home_title")} </h1>
              <div className="row align-items-end">
                <div className="col-lg-4 col-md-4 col-sm-6 col-6">
                  {list &&
                    list.map((item, index) => (
                      <div
                        className="d-flex justify-content-between"
                        key={index} 
                      >
                        <a href={`https://${item.facebook}`} target="_blank" ><i className="fab fa-facebook-f"></i></a>
                        <a href={`https://${item.instagram}`} target="_blank"><i className="fab fa-instagram"></i></a>
                        <a href={`https://${item.youtube}`} target="_blank" ><i className="fab fa-youtube"></i></a>
                        <a href={`https://wa.me/${item.whatsapp}`} target="_blank"  ><i className="fab fa-whatsapp"></i></a>
                        <a href={`https://${item.snapchat}`} target="_blank" ><i className="fab fa-snapchat-square"></i></a>
                        <a href={`https://${item.tiktok}`} target="_blank"><img src={require('../../assets/img/tiktok.png')} alt="tik-tok"
                          style={{ width: '15px', position: 'relative', top: '-2px' , filter:'brightness(0) invert(1)' }} /></a> 
                      </div>
                    ))}
                </div>
                <div className="col-lg-8 col-md-8 col-sm-6 col-6 text-end">
                  <LanguageDropDown />
                </div>
              </div>
            </div>
          </div>
          <CategoryPage />
        </Container>
      )}
    </>
  );
};

export default Home;
