import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import { Link, NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useState } from "react";
import { BILL, NEED_HELP, ORDER, PRIVACY } from "../../constants/urls";
import axios from "axios";
import { useTranslation } from "react-i18next";
import OrderContext from "../../store/OrderContext";
import SearchBar from "../../components/SearchBar";
const Footer = () => {
  const orderContext = useContext(OrderContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [message, setMessage] = useState(false);
  const location = useLocation();
  const splitLocation = pathname.split("/");


  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };
  const handleNeedHelp = async () => {
    setLoading(true);
    const data = {};
    await axios
      .post(NEED_HELP, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem('token');
          navigate("/");
        }
      });
    setLoading(false);
  };
  const handleBill = async () => {
    setLoading(true);
    const data = {};
    await axios
      .post(BILL, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setOpen(false);
        setTimeout(() =>
          window.location.reload(),
          localStorage.removeItem('token'),
          navigate('/'),
          5000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem('token');
          navigate("/");
        }
      });
    setLoading(false);
  };
  const handlePrivacy = async () => {
    setLoading(true);
    const data = {};
    await axios
      .post(PRIVACY, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.reload();
          localStorage.removeItem('token');

          navigate("/");
        }
      });
    setLoading(false);
  };

  let displayStyle = "";
  if (pathname === "/orders") {
    displayStyle = "none";
  }



  const handleClickRef = (e) => {
    if (pathname === "/" || pathname === "/about-us") {
      navigate("/items/trend")
      setTimeout(() => {
        orderContext.refInput.current.focus();
      }, 500);

    } else {
      orderContext.refInput.current.focus();
    }
    const btnSearch = document.querySelector('.btn-srh');
    document.addEventListener('click', event => {
      if (event.target != btnSearch) {
        btnSearch.classList.remove('active');
      } else {
        btnSearch.classList.add('active');
      }
    });
  };

  return (
    <>
      {token ?
        <>
          <div
            open={open}
            className="blur-section"
            style={
              open
                ? {
                  opacity: "1",
                  zIndex: "1",
                  position: "fixed",
                  width: "100%",
                  left: "0",
                  top: "0",
                }
                : { opacity: "0" }
            }
          ></div>
          <Container
            maxWidth="sm"
            style={{ display: displayStyle }}
            sx={{ padding: "0" }}
          >
            <Container
              maxWidth="sm"
              className="bottom-footer px-0"
              sx={{ padding: "0" }}
            >
              <footer className="position-relative ">
                <div className="help-section">
                  <Box sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={open}
                        onClick={handleClose}
                      >
                        <div className="loading-bg text-center">
                          <CircularProgress color="inherit" size={50} />
                          <br></br>
                          <div className="title text-white">{t("message_wait")}</div>
                        </div>
                      </Backdrop>
                    ) : (
                      <SpeedDial
                        ariaLabel="SpeedDial openIcon example"
                        icon={<SpeedDialIcon openIcon={<CloseIcon />} />}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                      >
                        
                        <SpeedDialAction
                          tooltipTitle="send notify"
                          onClick={handlePrivacy}
                        />
                        <SpeedDialAction
                          tooltipTitle="send notify"
                          onClick={handleNeedHelp}
                        />
                        <SpeedDialAction
                          tooltipTitle="send notify"
                          onClick={handleBill}
                        />
                      </SpeedDial>
                    )}
                  </Box>
                </div>

                <div className="row justify-content-center row-links">
                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "/" ? "active" : ""} to="/">
                      <i className="far fa-home-alt"></i>

                      <span>{t("home_link")}</span>
                    </NavLink>
                  </div>

                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "orders" ? "active" : ""} to="/orders">
                      <i className="far fa-heart"></i>

                      <span> {t("myOrder_link")}</span>
                    </NavLink>
                  </div>

                  <div className="col-3 text-center">
                    <button className="btn-srh" onClick={handleClickRef}>
                      <i className="fal fa-search"></i>
                      <span> {t("search_link")}</span>
                    </button>

                  </div>
                  <div className="col-3 text-center">
                    <NavLink className={splitLocation[1] === "about-us" ? "active" : ""} to="/about-us">
                      <i className="fal fa-info-circle"></i>

                      <span>{t("about_link")}</span>
                    </NavLink>
                  </div>
                </div>
              </footer>
            </Container>
          </Container>
          <Snackbar
            open={message}
            autoHideDuration={4000}
            onClose={handleCloseMessage}
            className="ic"
          >
            <Alert
              onClose={handleCloseMessage}
              severity="success"
              sx={{ width: "100%" }}
              style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
            >
              {t("success_message")}
            </Alert>
          </Snackbar>
        </>
        : ''}
    </>
  );
};

export default Footer;
